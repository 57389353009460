// src/keycloak.js
import Keycloak from 'keycloak-js';

function getKeycloakUrl() {
  const hostname = window.location.hostname;
  
  if (hostname === "localhost") {
    return 'https://auth.alpha.embracecloud.io/auth';  // Use 'alpha' environment for localhost
  }

  const parts = hostname.split('.'); // e.g., ["playground", "alpha", "embracecloud", "io"]
  
  if (parts.length < 3) return 'https://auth.embracecloud.nl/auth'; // Fallback URL

  const env = parts[1]; // Assuming the environment is always in the second part
  const tld = parts.slice(-2).join('.'); // Gets the top-level domain, e.g., "embracecloud.io"

  return `https://auth.${env}.${tld}/auth`;
}

export async function getRolesFromUserinfo(tenant, bearer) {
  const response = await fetch(`${getKeycloakUrl()}/realms/${tenant}/protocol/openid-connect/userinfo`, {
      headers: {
          'Authorization': `Bearer ${bearer}`
      }
  });
  const responseBody = await response.text();
  console.log(responseBody)


  return responseBody;
}




export const getKeycloakInstance = (tenant = 'embracecloud') => {
  const keycloakConfig = {
    url: getKeycloakUrl(),
    realm: tenant,
    clientId: "embracecloud",
  };

  return new Keycloak(keycloakConfig);
};

export default getKeycloakInstance;