export function getmeshUrl() {
    const hostname = window.location.hostname;
    
    if (hostname === "localhost") {
      return 'https://mesh-router.alpha.embracecloud.io/graphql';  // Use 'alpha' environment for localhost
    }
  
    const parts = hostname.split('.'); // e.g., ["playground", "alpha", "embracecloud", "io"]
    
    if (parts.length < 3) return 'https://mesh-router.embracecloud.nl/graphql'; // Fallback URL
  
    const env = parts[1]; // Assuming the environment is always in the second part
    const tld = parts.slice(-2).join('.'); // Gets the top-level domain, e.g., "embracecloud.io"
  
    return `https://mesh-router.${env}.${tld}/graphql`;
  }
  