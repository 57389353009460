import React, { useEffect, useState } from 'react';
import {getKeycloakInstance, getRolesFromUserinfo} from './keycloak'
import { GraphiQL } from 'graphiql';
import { createGraphiQLFetcher } from '@graphiql/toolkit';
import { explorerPlugin } from '@graphiql/plugin-explorer';
import { jwtDecode } from "jwt-decode";
import { Modal } from 'react-responsive-modal';

import {
  ToolbarButton
} from '@graphiql/react';

import '@graphiql/plugin-explorer/dist/style.css';
import { getmeshUrl } from './mesh';

function App() {
  const [token, setToken] = useState('');
  const [roles,setRoles] = useState('');
  const [rolesModalOpen, setRolesModalOpen] = useState(false);
  const [response, setResponse] = useState();
  const queryParams = new URLSearchParams(window.location.search);
  const tenant = queryParams.get('tenant') || 'embracecloud';  // Get client_id from URL or fallback to default
  


  const keycloak = getKeycloakInstance(tenant);  // Use the dynamic clientId


  useEffect(() => {
    keycloak.init({ onLoad: 'login-required', scope: "identity-provider-user portal-user" })
      .then(authenticated => {
        if (authenticated) {
          setToken(keycloak.token);
          getRolesFromUserinfo(tenant,keycloak.token).then(roles => {
            setRoles(roles);
          }).catch(exception => {
            console.log(exception);
          })
          
          keycloak.onTokenExpired = () => {
            keycloak.updateToken(30)
              .then(refreshed => {
                if (refreshed) {
                  setToken(keycloak.token);
                } else {
                  console.warn('Token refresh failed');
                }
              }).catch(error => console.error('Failed to refresh token:', error));
          };
        } else {
          console.error("User not authenticated");
        }
      }).catch(console.error);
  }, []);

  const fetcher = createGraphiQLFetcher({
    url: getmeshUrl(),
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const handleRoleClick = () => {    
    setResponse('');
    setResponse(roles);  // Set the custom response to be displayed
  };

  const explorer = explorerPlugin();

  return (
    <div style={{minHeight:'100vh'}} className="container">
      {token ? 
      <GraphiQL response={response} fetcher={fetcher} plugins={[explorer]} >
        <GraphiQL.Logo>
          Tenant: {tenant} <br></br>
          User: {jwtDecode(token)['email']} <br></br>
        

        </GraphiQL.Logo>

        <GraphiQL.Toolbar>
        <ToolbarButton onClick={handleRoleClick} label="My active roles">
          Roles
      </ToolbarButton>
        </GraphiQL.Toolbar>

   
      </GraphiQL> : <div>Loading or not authenticated...</div>}

    </div>
  );
}

export default App;
